<template>
  <div class="d-flex align-items-center row w-100">
    <custom-multi-select
      class="my-0"
      style="margin-bottom: 0 !important;"
      :title="$t(title)"
      :max="1"
      :options="values"
      :placeholder="$t('general.all')"
      :model.sync="modelProxy"
      :name="name"
      :width="width"
      :height="height"
      :is-inline="isInline"
      :is-filter="true"
      :not-list="!multiple"
      :object-key="objectKey"
      :item-per-row="itemPerRow"
      :size="size"
      :show-invalid="false"
      input-width="100%"
      :required="required"
    ></custom-multi-select>
  </div>
</template>

<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
export default {
  name: "SelectFilter",
  props: {
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    title: {},
    name: {},
    values: {},
    model: {},
    isInline: {
      required: false,
      default: false,
    },
    multiple: {
      required:false,
      default: false,
    },
    width: {
      required: false,
      default: '300px',
    },
    height: {
      required: false,
      default: null,
    },
    required: {
      required: false,
      default: true,
    },
    objectKey: {
      required: false,
      default: null
    },
    itemPerRow: {
      required: false,
      default: 3,
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].indexOf(l) > -1;
      }
    },

  },
  components: {
    CustomMultiSelect,
    InfoTooltip
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        if(value === 'null') {
          this.$emit('update:model', null)
        }else{
          this.$emit('update:model', value)
          this.$emit('change', ['status', value])
        }
      }
    }
  },
}
</script>

<style scoped>

</style>